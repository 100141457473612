import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import Button from 'components/shared/button'
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const ProductsSliderContainer = styled(Container)`
  padding: 60px 10px 0 10px;

  ${media.lg.min} {
    padding: 80px 30px 0 30px;
  }
`

const ProductWrapper = styled.div`
  display: block;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.midnightgreen};
  border-radius: 5px;
  padding: 30px 26px 20px 26px;
  transition: 0.3s;
  margin-bottom: 20px;

  ${media.lg.min} {
    border-color: transparent;
    margin-bottom: 0;

    &:hover {
      border-color: ${({ theme }) => theme.colors.midnightgreen};

      /* display button on hover */
      button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

const ImageWrapper = styled.div`
  height: 220px;

  ${media.lg.min} {
    height: 350px;
  }
`

const StyledProductNumber = styled.p`
  position: absolute;
  font-size: 24px;
  font-weight: 600;
  top: 36px;
  left: 26px;
  z-index: 1;

  ${media.lg.min} {
    top: 40px;
    left: 40px;
  }
`

const StyledProductName = styled.p`
  margin: 20px 0;
  font-size: 18px;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  display: block;
  background: transparent;
  color: ${({ theme }) => theme.colors.midnightgreen};
  width: fit-content;
  margin-left: auto;
  transition: 0.3s;

  ${media.lg.min} {
    visibility: hidden;
    opacity: 0;

    &:hover {
      background-color: ${({ theme }) => theme.colors.midnightgreen};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

type Props = {
  products: Array<{
    image: {
      src: ImageDataLike
      alt: string
    }
    name: string
    link: string
  }>
}

const ProductsSlider: React.FC<Props> = ({ products }) => {
  const { lg } = useBreakpoint()

  return lg ? (
    <ProductsSliderContainer>
      <Swiper spaceBetween={lg ? 40 : 20} slidesPerView={lg ? 3 : 1} grabCursor>
        {products?.map(({ image, name, link }, index) => (
          <SwiperSlide key={`${index}-${name}`}>
            <ProductWrapper as="a" href={link} target="_blank">
              <StyledProductNumber
                dangerouslySetInnerHTML={{ __html: `${index + 1}` }}
              />
              <ImageWrapper>
                <LazyImage src={image?.src} alt={image?.alt} fill />
              </ImageWrapper>
              <StyledProductName dangerouslySetInnerHTML={{ __html: name }} />
              <StyledButton>Przejdź do sklepu</StyledButton>
            </ProductWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </ProductsSliderContainer>
  ) : (
    <ProductsSliderContainer>
      {products?.map(({ image, name, link }, index) => (
        <ProductWrapper
          as="a"
          href={`${link}/`}
          target="_blank"
          key={`${index}-${name}`}
        >
          <StyledProductNumber
            dangerouslySetInnerHTML={{ __html: `${index + 1}` }}
          />
          <ImageWrapper>
            <LazyImage src={image?.src} alt={image?.alt} fill />
          </ImageWrapper>
          <StyledProductName dangerouslySetInnerHTML={{ __html: name }} />
          <StyledButton>Przejdź do sklepu</StyledButton>
        </ProductWrapper>
      ))}
    </ProductsSliderContainer>
  )
}

export default ProductsSlider
