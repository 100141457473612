import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Heading } from 'components/shared/typography'
import Container from 'components/shared/container'
import Button from 'components/shared/button'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledWrapper = styled.div`
  position: relative;
  margin: 80px 0;

  ${media.lg.min} {
    margin: 100px 0;
  }

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
`

const StyledContentWrapper = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    padding-top: 170px;
    padding-bottom: 170px;
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: 80px;

  button {
    width: 100%;
  }

  ${media.lg.min} {
    margin-top: 0;
    padding-right: 70px;
    justify-content: flex-end;

    button {
      width: fit-content;
    }
  }
`

const ActionBox = () => {
  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <StaticImage
        src="../assets/images/action-box-shop-image.jpg"
        alt="tło"
        className="background-image"
      />
      <StyledContentWrapper>
        <Heading
          as="h3"
          themecolor="white"
          line={lg ? '70px' : '45px'}
          weight={700}
          margin="0"
          size={lg ? 34 : 26}
          sizeDiff={0}
        >
          Sprawdź całą ofertę Systemu Elfa
        </Heading>
        <StyledButtonWrapper>
          <Button as="a" href="https://homeden.pl/system-elfa" target="_blank">
            Przejdź do sklepu
          </Button>
        </StyledButtonWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

export default ActionBox
