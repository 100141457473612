import React, { useState } from 'react'
import SwiperInstance, { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const StyledImagesSliderContainer = styled(Container)`
  padding: 0;

  ${media.lg.min} {
    padding: 0 30px;
  }

  .swiper-slide {
    height: 550px;

    ${media.lg.min} {
      height: 650px;
    }
  }

  .swiper {
    .swiper-scrollbar {
      position: static;
      margin-top: 40px;
      height: 3px;
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: 0;

      ${media.lg.min} {
        margin-top: 60px;
      }

      &-drag {
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
`

const StyledControlButtonsWrapper = styled.div`
  padding: 45px 36px 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 40px;

  ${media.lg.min} {
    padding: 40px 0 0 0;
  }
`

const StyledControlButton = styled.button<{ inActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midnightgreen};
  cursor: pointer;

  &:first-of-type {
    svg {
      transform: rotate(180deg);
    }
  }

  ${({ inActive }) =>
    inActive &&
    css`
      border-color: rgba(0, 0, 0, 0.5);
      cursor: auto;

      svg path {
        fill: rgba(0, 0, 0, 0.5);
      }
    `}
`
type Props = {
  images: Array<{
    src: ImageDataLike
    alt: string
  }>
}

const ImagesSlider: React.FC<Props> = ({ images }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <StyledImagesSliderContainer>
      <Swiper
        modules={[Scrollbar]}
        onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
        onSlideChange={() => setActiveIndex(swiper?.realIndex ?? 0)}
        spaceBetween={0}
        slidesPerView={1}
        grabCursor
        scrollbar={{ draggable: true }}
      >
        {images?.map(({ src, alt }, index) => (
          <SwiperSlide key={`${index}-${alt}`}>
            <LazyImage src={src} alt={alt} fill />
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledControlButtonsWrapper>
        <StyledControlButton
          onClick={goPrev}
          aria-label="poprzedni slajd"
          inActive={activeIndex === 0}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.201"
            height="20.401"
            viewBox="0 0 10.201 20.401"
          >
            <path
              id="Path_271"
              data-name="Path 271"
              d="M10,27.4,20.2,17.2,10,7Z"
              transform="translate(-10 -7)"
              fill="#084c62"
            />
          </svg>
        </StyledControlButton>
        <StyledControlButton
          onClick={goNext}
          aria-label="kolejny slajd"
          /* eslint-disable no-unsafe-optional-chaining */
          inActive={activeIndex === swiper?.slides.length! - 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.201"
            height="20.401"
            viewBox="0 0 10.201 20.401"
          >
            <path
              id="Path_271"
              data-name="Path 271"
              d="M10,27.4,20.2,17.2,10,7Z"
              transform="translate(-10 -7)"
              fill="#084c62"
            />
          </svg>
        </StyledControlButton>
      </StyledControlButtonsWrapper>
    </StyledImagesSliderContainer>
  )
}

export default ImagesSlider
