import React from 'react'
import styled from 'styled-components'
import { navigate, PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Breadcrumbs from 'components/shared/breadcrumbs'
import Container from 'components/shared/container'
import Icon from 'components/shared/icon'

import ActionBoxShop from 'components/action-box-shop'
import ImagesSlider from 'components/pages/room/images-slider'
import ProductsSlider from 'components/pages/room/products-slider'

import media from 'styles/media'

import arrowIcon from 'assets/icons/arrow-dropdown.svg'

import { RoomPageQuery } from 'types/graphql'

const IntroContainer = styled(Container)`
  padding-bottom: 40px;

  ${media.lg.min} {
    padding-bottom: 60px;
  }
`

const GoBackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    transform: rotate(180deg);
  }

  p {
    margin-left: 20px;
    font-weight: 700;
  }
`

const StyledHeading = styled.h1`
  font-size: 26px;
  line-height: 33px;
  margin-top: 40px;
  margin-bottom: 20px;

  ${media.lg.min} {
    max-width: 826px;
  }
`

const StyledText = styled.h2`
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  font-family: 'Open Sans';

  ${media.lg.min} {
    max-width: 826px;
  }
`

const Room: React.FC<PageProps<RoomPageQuery>> = ({ data }) => {
  const PAGE = data?.wpRoomArticle
  const PAGE_SEO = data?.wpRoomArticle?.seo!

  const IMAGES_GALLERY =
    PAGE?.pomieszczenie?.imagesGallery?.map((image) => ({
      src: image?.galleryImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: image?.galleryImg?.altText!,
    })) || []

  const PRODUCTS_GALLERY =
    PAGE?.pomieszczenie?.productsGallery?.map((product) => ({
      image: {
        src: product?.productImg?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: product?.productImg?.altText!,
      },
      name: product?.productName!,
      link: product?.productLink!,
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Breadcrumbs
        spacing
        crumbs={[
          {
            name: 'Pomieszczenia',
            to: '/pomieszczenia',
          },
          {
            name: PAGE?.title!,
          },
        ]}
      />
      <IntroContainer>
        <GoBackButton onClick={() => navigate(-1)}>
          <Icon src={arrowIcon} width={8} height={14} />
          <p>Wróć</p>
        </GoBackButton>
        <StyledHeading>{PAGE?.title!}</StyledHeading>
        <StyledText
          dangerouslySetInnerHTML={{
            __html: PAGE?.pomieszczenie?.shortDescription!,
          }}
        />
      </IntroContainer>

      <ImagesSlider images={IMAGES_GALLERY} />
      <ProductsSlider products={PRODUCTS_GALLERY} />
      <ActionBoxShop />
    </Layout>
  )
}

export default Room

export const query = graphql`
  query RoomPage($slug: String) {
    wpRoomArticle(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      slug
      title
      pomieszczenie {
        shortDescription
        imagesGallery {
          galleryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        productsGallery {
          productName
          productLink
          productImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
